import React from 'react';

import Box from '../Box';
import Heading from '../Typography/Heading';
import Spacer from '../Spacer';
import HStack from '../Stack/HStack';

import PropTypes from 'prop-types';
import { HiOutlineXCircle } from 'react-icons/hi';

const OffCanvas = ({ isOpen, children, onClick, title }) => {
  return (
    <>
      <Box
        position="fixed"
        zIndex="5"
        top="0"
        left="0"
        width="100%"
        height="100%"
        backgroundColor="black"
        transition="opacity 0.25s, visibility 0.5s"
        opacity={isOpen ? '0.8' : '0'}
        visibility={isOpen ? 'visible' : 'hidden'}
        onClick={onClick}
        cursor="pointer"
      />
      <Box
        position="fixed"
        zIndex={{ mobile: '20', desktop: '10' }}
        top="0"
        right="0"
        height="100%"
        width="100%"
        maxWidth="460px"
        padding="medium"
        backgroundColor="white"
        overflow="auto"
        transform={`translateX(${isOpen ? '0%' : '100%'})`}
        transition="all .25s cubic-bezier(.07,.23,.34,1)"
      >
        <HStack alignItems="center" marginBottom="xlarge">
          <Heading>{title}</Heading>
          <Spacer />
          <Box onClick={onClick} cursor="pointer" display="flex">
            <HiOutlineXCircle size={26} />
          </Box>
        </HStack>
        {children}
      </Box>
    </>
  );
};

OffCanvas.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default OffCanvas;
