import mixpanel from 'mixpanel-browser';
import useAuthRecoil from './useAuthRecoil';

const useMixpanelEvents = () => {
  mixpanel.init('bdbe86b7a23baafccc27714fb447424c');

  const { auth } = useAuthRecoil();
  if (auth?.isAuth) {
    mixpanel.identify(auth.user.id);
  } else {
    mixpanel.reset();
  }

  const track = (trackEvent, customProperties) => {
    mixpanel.track(trackEvent, customProperties && { customProperties });
  };

  return { track };
};

export default useMixpanelEvents;
