import React from 'react';
import PropTypes from 'prop-types';
import HStack from './Stack/HStack';
import Box from './Box';
import Spacer from './Spacer';
import SmallCopy from './Typography/SmallCopy';

const Badge = ({ children, onClick, id, type, height = '36px' }) => {
  const resolveColorsByType = () => {
    switch (type) {
      case 'info': {
        return 'cian';
      }

      case 'danger': {
        return 'red';
      }

      default: {
        return 'green';
      }
    }
  };

  return (
    <Box
      tag="span"
      borderWidth="0.1px"
      borderStyle="solid"
      borderColor={resolveColorsByType()}
      borderRadius="large"
      minWidth="90px"
      paddingX="small"
      display="flex"
      alignItems="center"
      height={height}
      backgroundColor={resolveColorsByType()}
    >
      <Box flexGrow="1">
        <HStack alignItems="center" space={onClick ? 'xsmall' : undefined}>
          <Box width="100%" textAlign={onClick ? 'left' : 'center'}>
            {children}
          </Box>
          {onClick && (
            <>
              <Spacer />
              <div>
                <Box
                  tag="button"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="20px"
                  height="20px"
                  borderRadius="full"
                  borderWidth="0.1px"
                  borderStyle="solid"
                  borderColor="purple"
                  onClick={() => onClick({ id })}
                  backgroundColor="purple"
                >
                  <SmallCopy tag="span" color="white">
                    &times;
                  </SmallCopy>
                </Box>
              </div>
            </>
          )}
        </HStack>
      </Box>
    </Box>
  );
};

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  height: PropTypes.string,
};

export default Badge;
