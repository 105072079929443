import { atom } from 'recoil';
import localStorageEffect from './localStorageEffect';

export const initialState = {
  isContinued: false,
  selectedBank: null,
  voucher: null,
  checkCash: false,
  amountFromWallet: 0,
  couponAmount: 0,
  useMoneyFromWallet: false,
  useWalletType: '',
  amountFromMethod: 0,
  paymentMethod: null,
  currencyId: null,
  investmentId: null,
  projectId: null,
  paymentAmount: null,
  paymentCoupon: null,
  paymentCurrency: null,
  isLegalPerson: false,
};

export const newInvesmentState = atom({
  key: 'newInvesmentState',
  default: initialState,
  effects_UNSTABLE: [localStorageEffect('invesments')],
});
