import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from './ButtonBase';

const Share = ({
  type = 'button',
  onClick,
  children,
  disabled,
  size,
  loading,
  iconStart,
  iconEnd,
  tag,
  href,
  download,
  target,
  alt,
}) => {
  return (
    <ButtonBase
      type={type}
      onClick={onClick}
      disabled={disabled}
      size={size}
      loading={loading}
      iconStart={iconStart}
      iconEnd={iconEnd}
      backgroundColor="white"
      boxShadow="medium"
      borderColor="transparent"
      color="purple"
      borderRadius="large"
      horizontalCenter={false}
      tag={tag}
      href={href}
      download={download}
      target={target}
      alt={alt}
      display="flex"
      alignItems="center"
      textDecoration="none"
      hover={{ backgroundColor: '_xlightGray' }}
    >
      {children}
    </ButtonBase>
  );
};

Share.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  size: PropTypes.string,
  loading: PropTypes.bool,
  iconStart: PropTypes.node,
  iconEnd: PropTypes.node,
  tag: PropTypes.string,
  href: PropTypes.string,
  download: PropTypes.bool,
  target: PropTypes.string,
  alt: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default Share;
