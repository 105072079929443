import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Background = styled.span`
  background: ${(props) =>
    `linear-gradient(180deg, transparent 41%, ${props.color} 90%)`};
  border-radius: 10px;
  padding-right: ${(props) => props.padding};
  position: relative;
  font-weight: ${(props) => props.fontWeight};
`;

const Decorator = ({
  children,
  width = '-30px',
  color = process.env.REACT_APP_CUSTOM_CONFIG_COLOR_PRIMARY_GRADIENT,
  padding = '5px',
  fontWeight,
}) => {
  return (
    <Background
      width={width}
      color={color}
      padding={padding}
      fontWeight={fontWeight}
    >
      {children}
    </Background>
  );
};

Decorator.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string,
  color: PropTypes.string,
  padding: PropTypes.string,
  fontWeight: PropTypes.string,
};

export default Decorator;
