import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';

const TabContent = ({ children, paddingY = 'medium' }) => {
  return <Box paddingY={paddingY}>{children}</Box>;
};

TabContent.propTypes = {
  children: PropTypes.node.isRequired,
  paddingY: PropTypes.string,
};

export default TabContent;
