import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from './ButtonBase';

const Link = ({
  type = 'button',
  onClick,
  children,
  disabled,
  size,
  loading,
  iconStart,
  iconEnd,
  variant,
  tag,
  href,
  download,
  target,
  alt,
  noSpaces,
  textDecoration = 'underline',
  ...rest
}) => {
  const resolveColor = () => {
    switch (variant) {
      case 'white': {
        return 'white';
      }

      case 'red': {
        return 'red';
      }

      case 'green': {
        return 'green';
      }
      case '_green': {
        return '_green';
      }
      case 'darkBlue': {
        return 'darkBlue';
      }

      default: {
        return 'purple';
      }
    }
  };

  const addClasses = rest.addClass ? rest.addClass : '';

  return (
    <ButtonBase
      type={type}
      onClick={onClick}
      disabled={disabled}
      size={size}
      loading={loading}
      iconStart={iconStart}
      iconEnd={iconEnd}
      backgroundColor="transparent"
      borderColor="transparent"
      color={resolveColor()}
      textDecoration={textDecoration}
      tag={tag}
      href={href}
      download={download}
      target={target}
      alt={alt}
      noSpaces={noSpaces}
      className={`${addClasses}`}
    >
      {children}
    </ButtonBase>
  );
};

Link.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  size: PropTypes.string,
  variant: PropTypes.string,
  loading: PropTypes.bool,
  iconStart: PropTypes.node,
  iconEnd: PropTypes.node,
  tag: PropTypes.string,
  href: PropTypes.string,
  download: PropTypes.bool,
  target: PropTypes.string,
  alt: PropTypes.string,
  noSpaces: PropTypes.bool,
  textDecoration: PropTypes.string,
};

export default Link;
