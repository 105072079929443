import React from 'react';
import Box from './Box';
import ReactPlayer from 'react-player/youtube';
import PropTypes from 'prop-types';
import VideoPlaceholderImage from '../../assets/images/video_preview.png';
import { BsPlayFill } from 'react-icons/bs';

const VideoPlayer = ({ url, placeholderImage, togglePlay, isPlayed }) => {
  return (
    <Box
      width="100%"
      height={{ mobile: '250px', desktop: '450px' }}
      position="relative"
      borderRadius="large"
      overflow="hidden"
    >
      {!isPlayed && placeholderImage && (
        <Box onClick={togglePlay} cursor="pointer">
          <Box
            backgroundImage={`url(${
              placeholderImage ? placeholderImage : null
            })`}
            backgroundSize="cover"
            backgroundPosition="center center"
            top="0"
            left="0"
            width="100%"
            height="100%"
            position="absolute"
          />
          <Box
            position="absolute"
            backgroundColor="green"
            width="50px"
            height="50px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="full"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <BsPlayFill color="white" size={22} />
          </Box>
        </Box>
      )}
      <ReactPlayer
        width="100%"
        height="100%"
        url={url}
        playing={isPlayed}
        controls={false}
        onPause={togglePlay}
      />
    </Box>
  );
};

VideoPlayer.propTypes = {
  placeholderImage: PropTypes.string,
  url: PropTypes.string.isRequired,
  togglePlay: PropTypes.func,
  isPlayed: PropTypes.bool,
};

export default VideoPlayer;
