import React from 'react';
import PropTypes from 'prop-types';
import InputBase from './InputBase';

const Primary = ({
  name,
  type,
  label,
  placeholder,
  value,
  onChange,
  error,
  disabled,
  size,
  autoFocus,
  keyboardType,
  minLength,
  maxLength,
  borderWidth,
  labelColor = 'textBlack',
  borderColor = 'lightGray',

  labelSize,
  ...restProps
}) => {
  return (
    <InputBase
      tag="input"
      name={name}
      type={type}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      error={error}
      disabled={disabled}
      size={size}
      borderWidth={borderWidth}
      autoFocus={autoFocus}
      keyboardType={keyboardType}
      minLength={minLength}
      maxLength={maxLength}
      color="textBlack"
      borderColor={borderColor}
      labelColor={labelColor}
      labelSize={labelSize}
      {...restProps}
    />
  );
};

Primary.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  onChange: PropTypes.func,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  autoFocus: PropTypes.bool,
  keyboardType: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  labelColor: PropTypes.string,
  labelSize: PropTypes.string,
  borderWidth: PropTypes.string,
  borderColor: PropTypes.string,
};

// Memo to prevent re-render if any of his props doesnt change.
export default React.memo(Primary);
