import React from 'react';
import ButtonBase from './ButtonBase';
import PropTypes from 'prop-types';

const NewTertiary = ({
  type = 'button',
  onClick,
  children,
  disabled,
  size,
  loading,
  iconStart,
  iconEnd,
  tag,
  href,
  download,
  target,
  alt,
  fontSize,
  variant,
  buttonDesign = 'tertiary',
  ...restProps
}) => {
  const availableColors = {
    green: { defaultColor: '_green', hoverColor: '_xlightGray' },
    purple: { defaultColor: 'purple', hoverColor: '_xlightGray' },
  };
  const colorVariant = availableColors[variant] || availableColors['green'];
  return (
    <ButtonBase
      type={type}
      onClick={onClick}
      disabled={disabled}
      size={size}
      loading={loading}
      iconStart={iconStart}
      iconEnd={iconEnd}
      backgroundColor="white"
      borderColor="white"
      color={colorVariant.defaultColor}
      tag={tag}
      href={href}
      download={download}
      target={target}
      alt={alt}
      hover={{
        backgroundColor: colorVariant.hoverColor,
        borderColor: colorVariant.hoverColor,
      }}
      fontSize={fontSize}
      buttonDesign={buttonDesign}
      boxShadow="large"
      {...restProps}
    >
      {children}
    </ButtonBase>
  );
};

NewTertiary.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  size: PropTypes.string,
  loading: PropTypes.bool,
  iconStart: PropTypes.node,
  iconEnd: PropTypes.node,
  tag: PropTypes.string,
  href: PropTypes.string,
  download: PropTypes.bool,
  target: PropTypes.string,
  alt: PropTypes.string,
  fontSize: PropTypes.string,
  variant: PropTypes.string,
  buttonDesign: PropTypes.string,
};

export default NewTertiary;
