import { useEffect } from 'react';
import { useRecoilSnapshot } from 'recoil';

const useRecoilDebugger = () => {
  const snapshot = useRecoilSnapshot();

  useEffect(() => {
    console.debug('The following atoms were modified:');

    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.debug(node.key, snapshot.getLoadable(node));
    }
  }, [snapshot]);
};

export default useRecoilDebugger;
