import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from './Box';

const Switch = ({ value, onChange, name, size, disabled }) => {
  return (
    <Wrapper
      position="relative"
      display="inline-block"
      width={size === 'big' ? '45px' : '32px'}
      height={size === 'big' ? '22.5px' : '14px'}
      onClick={() => (disabled ? null : onChange())}
      size={size}
    >
      <input
        name={name}
        type="checkbox"
        checked={value}
        onChange={() => null}
      />
      <Slider
        tag="span"
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        backgroundColor="_midLightGray"
        borderRadius="large"
        cursor={disabled ? 'not-allowed' : 'pointer'}
        transition="all 200ms ease"
        size={size}
        opacity={disabled ? 0.4 : 1}
        width="38px"
      />
    </Wrapper>
  );
};

Switch.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  disabled: PropTypes.bool,
};

const Wrapper = styled(Box)`
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + span {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  input:focus + span {
    box-shadow: 0 0 1px ${({ theme }) => theme.colors.secondary};
  }

  input:checked + span {
    &::before {
      transition: all 150ms ease;
      transform: ${({ size }) =>
    size === 'big' ? 'translateX(15px)' : 'translateX(22px)'};
    }
  }
`;

const Slider = styled(Box)`
  &::before {
    position: absolute;
    content: '';
    height: ${({ size }) => (size === 'big' ? '19.5px' : '12px')};
    width: ${({ size }) => (size === 'big' ? '19.5px' : '12px')};
    left: 5%;
    bottom: ${({ size }) => (size === 'big' ? '1.5px' : '0.5px')};
    background-color: ${({ theme }) => theme.colors.white};
    transition: all 150ms ease;
    border-radius: 50%;
  }
`;

export default Switch;
