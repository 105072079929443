import { Box, Spinner } from './SimpleUI';
import React from 'react';

const PageLoading = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    width="100%"
    minHeight="100vh"
  >
    <Spinner />
  </Box>
);

export default PageLoading;
