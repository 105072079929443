import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useGetNotifications from '../hooks/useGetNotifications';
import useUpdateNotification from '../hooks/useUpdateNotification';
import { Box, Copy } from './SimpleUI';

const IsReadMark = styled(Box)`
  &:after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    background: red;
    border-radius: 50%;
    top: 20px;
    right: 15px;
  }
`;

const NotificationsStatus = ({ item, toggleNotifications }) => {
  const { title, message, isRead, href, id } = item;
  const history = useHistory();

  const { handleUpdateNotification } = useUpdateNotification();
  const { refetch } = useGetNotifications();

  const data = {
    variables: {
      updateNotif: {
        notificationId: id,
        status: 1, // 1 = true, 0 = false
      },
    },
  };

  const updateAndRefetch = useCallback(async () => {
    let response = await handleUpdateNotification(data);
    response = await refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNotificationStatus = () => {
    toggleNotifications();
    !isRead && updateAndRefetch();
    href !== '' && history.push(href);
  };

  return (
    <Box
      padding="medium"
      paddingRight="xlarge"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      borderRadius="small"
      borderStyle="solid"
      borderWidth="1px"
      hover={{ backgroundColor: 'xxlightGray' }}
      backgroundColor={isRead ? 'white' : 'xlightGray'}
      borderColor={isRead ? 'lightGray' : 'transparent'}
      onClick={handleNotificationStatus}
      cursor="pointer"
      position="relative"
    >
      <Copy
        fontWeight="medium"
        textTransform="uppercase"
        color={isRead ? 'purple' : 'green'}
      >
        {title}
      </Copy>
      <Copy>{message}</Copy>
      {!isRead && <IsReadMark title="Marcar como leído" />}
    </Box>
  );
};

NotificationsStatus.propTypes = {
  item: PropTypes.object,
  toggleNotifications: PropTypes.func,
};

export default NotificationsStatus;
