import React from 'react';
import PropTypes from 'prop-types';
import BaseTypography from './Base';

const Copy = ({
  tag = 'p',
  textAlign,
  textTransform,
  color = 'textBlack',
  children,
  fontWeight = 'regular',
  lineHeight = '1.4',
  className = null,
  fontSize = 'copy',
  textShadow,
}) => {
  return (
    <BaseTypography
      tag={tag}
      textAlign={textAlign}
      textTransform={textTransform}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      className={className}
      textShadow={textShadow}
    >
      {children}
    </BaseTypography>
  );
};

Copy.propTypes = {
  tag: PropTypes.string,
  textAlign: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textTransform: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  fontWeight: PropTypes.string,
  fontFamily: PropTypes.string,
  lineHeight: PropTypes.string,
  className: PropTypes.string,
  fontSize: PropTypes.string,
  textShadow: PropTypes.string,
};

export default Copy;
