import React from 'react';
import CardBase from './CardBase';
import PropTypes from 'prop-types';

const Quaternary = ({
  tag,
  children,
  loading,
  loadingHeight,
  noContentSpace,
  boxShadow,
  borderRadius = 'small',
}) => {
  return (
    <CardBase
      tag={tag}
      borderColor="transparent"
      backgroundColor="white"
      loading={loading}
      loadingHeight={loadingHeight}
      noContentSpace={noContentSpace}
      boxShadow={boxShadow}
      borderRadius={borderRadius}
    >
      {children}
    </CardBase>
  );
};

Quaternary.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  loadingHeight: PropTypes.number,
  noContentSpace: PropTypes.bool,
  boxShadow: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  borderRadius: PropTypes.string,
};

export default Quaternary;
