import useDesignUtils from '../../hooks/useDesignUtils';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Box = ({
  tag,
  minWidth,
  maxWidth,
  width,
  minHeight,
  maxHeight,
  height,
  display,
  margin,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  marginX,
  marginY,

  padding,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingX,
  paddingY,

  flex,
  flexDirection,
  flexWrap,
  flexGrow,
  flexShrink,
  flexBasis,
  justifyContent,
  alignItems,
  verticalAlign,

  borderColor,
  borderColorY,
  borderColorX,
  borderColorTop,
  borderColorBottom,
  borderColorLeft,
  borderColorRight,

  borderWidth,
  borderRadius,
  borderStyle,

  backgroundColor,

  $color,
  $fontWeight,
  $fontSize,
  textAlign,
  $letterSpacing,
  $textTransform,
  $fontFamily,
  $lineHeight,

  boxShadow,
  textShadow,
  textDecoration,
  listStyle,
  overflow,
  overflowX,
  overflowY,

  position,
  top,
  left,
  right,
  bottom,
  zIndex,
  cursor,
  transform,
  userSelect,

  transition,
  objectFit,
  opacity,
  visibility,
  background,
  backgroundImage,
  backgroundSize,
  backgroundPosition,
  backgroundRepeat,
  filter,
  float,
  useRef,
  hover,
  wordBreak,

  ...viewProps
}) => {
  const { resolveProps, resolveThemeProps } = useDesignUtils();

  const calculatedStyles = {
    minWidth: resolveProps(minWidth),
    maxWidth: resolveProps(maxWidth),
    width: resolveProps(width),

    minHeight: resolveProps(minHeight),
    maxHeight: resolveProps(maxHeight),
    height: resolveProps(height),
    display: resolveProps(display),

    marginTop: resolveThemeProps('scale', marginY || marginTop || margin),
    marginBottom: resolveThemeProps('scale', marginY || marginBottom || margin),
    marginLeft: resolveThemeProps('scale', marginX || marginLeft || margin),
    marginRight: resolveThemeProps('scale', marginX || marginRight || margin),

    paddingTop: resolveThemeProps('scale', paddingY || paddingTop || padding),
    paddingBottom: resolveThemeProps(
      'scale',
      paddingY || paddingBottom || padding,
    ),
    paddingLeft: resolveThemeProps('scale', paddingX || paddingLeft || padding),
    paddingRight: resolveThemeProps(
      'scale',
      paddingX || paddingRight || padding,
    ),

    flex: resolveProps(flex),
    flexDirection: resolveProps(flexDirection),
    flexWrap: resolveProps(flexWrap),
    flexGrow: resolveProps(flexGrow),
    flexShrink: resolveProps(flexShrink),
    flexBasis: resolveProps(flexBasis),
    justifyContent: resolveProps(justifyContent),
    alignItems: resolveProps(alignItems),
    verticalAlign: resolveProps(verticalAlign),

    borderTopColor: resolveThemeProps(
      'colors',
      borderColorY || borderColorTop || borderColor,
    ),
    borderBottomColor: resolveThemeProps(
      'colors',
      borderColorY || borderColorBottom || borderColor,
    ),
    borderRightColor: resolveThemeProps(
      'colors',
      borderColorX || borderColorRight || borderColor,
    ),
    borderLeftColor: resolveThemeProps(
      'colors',
      borderColorX || borderColorLeft || borderColor,
    ),

    borderWidth: resolveProps(borderWidth),
    borderStyle: resolveProps(borderStyle),

    fontWeight: resolveThemeProps('weight', $fontWeight),
    fontSize: resolveProps($fontSize),
    textAlign: resolveProps(textAlign),
    letterSpacing: resolveProps($letterSpacing),
    textTransform: resolveProps($textTransform),
    fontFamily: resolveProps($fontFamily),
    lineHeight: resolveProps($lineHeight),
    color: resolveThemeProps('fontColorVariants', $color),
    textDecoration: resolveProps(textDecoration),
    wordBreak: resolveProps(wordBreak),

    borderRadius: resolveThemeProps('radius', borderRadius),
    backgroundColor: resolveThemeProps('colors', backgroundColor),
    listStyle: resolveProps(listStyle),
    overflow: resolveProps(overflow),
    overflowX: resolveProps(overflowX),
    overflowY: resolveProps(overflowY),
    boxShadow: resolveThemeProps('shadows', boxShadow),
    textShadow: resolveThemeProps('textShadow', textShadow),

    position: resolveProps(position),
    top: resolveProps(top),
    left: resolveProps(left),
    right: resolveProps(right),
    bottom: resolveProps(bottom),
    zIndex: resolveProps(zIndex),
    cursor: resolveProps(cursor),
    transform: resolveProps(transform),
    userSelect: userSelect,

    transition: resolveProps(transition),
    objectFit: resolveProps(objectFit),
    opacity: resolveProps(opacity),
    visibility: resolveProps(visibility),
    background: resolveProps(background),
    backgroundImage: resolveProps(backgroundImage),
    backgroundSize: resolveProps(backgroundSize),
    backgroundPosition: resolveProps(backgroundPosition),
    backgroundRepeat: resolveProps(backgroundRepeat),
    filter: resolveProps(filter),
    float: resolveProps(float),

    ...(hover && {
      '&:hover': {
        backgroundColor: resolveThemeProps('colors', hover.backgroundColor),
        borderColor: resolveThemeProps('colors', hover.borderColor),
        color: resolveThemeProps('fontColorVariants', hover.color),
        filter: resolveProps(hover.filter),
        opacity: resolveProps(hover.opacity),
        cursor: resolveProps(hover.cursor),
        boxShadow: resolveThemeProps('shadows', hover.boxShadow),
      },
    }),
  };

  return (
    <View as={tag} ref={useRef} {...viewProps} finalStyles={calculatedStyles} />
  );
};

const View = styled.div(({ finalStyles }) => ({
  ...finalStyles,
}));

Box.propTypes = {
  tag: PropTypes.any,
  minWidth: PropTypes.any,
  maxWidth: PropTypes.any,
  width: PropTypes.any,
  minHeight: PropTypes.any,
  maxHeight: PropTypes.any,
  height: PropTypes.any,
  display: PropTypes.any,
  margin: PropTypes.any,
  marginTop: PropTypes.any,
  marginBottom: PropTypes.any,
  marginLeft: PropTypes.any,
  marginRight: PropTypes.any,
  marginX: PropTypes.any,
  marginY: PropTypes.any,

  padding: PropTypes.any,
  paddingTop: PropTypes.any,
  paddingBottom: PropTypes.any,
  paddingLeft: PropTypes.any,
  paddingRight: PropTypes.any,
  paddingX: PropTypes.any,
  paddingY: PropTypes.any,

  flex: PropTypes.any,
  flexDirection: PropTypes.any,
  flexWrap: PropTypes.any,
  flexGrow: PropTypes.any,
  flexShrink: PropTypes.any,
  flexBasis: PropTypes.any,
  justifyContent: PropTypes.any,
  alignItems: PropTypes.any,
  verticalAlign: PropTypes.any,

  borderColor: PropTypes.any,
  borderColorY: PropTypes.any,
  borderColorX: PropTypes.any,
  borderColorTop: PropTypes.any,
  borderColorBottom: PropTypes.any,
  borderColorLeft: PropTypes.any,
  borderColorRight: PropTypes.any,

  borderWidth: PropTypes.any,
  borderRadius: PropTypes.any,
  borderStyle: PropTypes.any,

  backgroundColor: PropTypes.any,

  $color: PropTypes.any,
  $fontWeight: PropTypes.any,
  $fontSize: PropTypes.any,
  textAlign: PropTypes.any,
  $letterSpacing: PropTypes.any,
  $textTransform: PropTypes.any,
  $fontFamily: PropTypes.any,
  $lineHeight: PropTypes.any,

  boxShadow: PropTypes.any,
  textShadow: PropTypes.any,
  textDecoration: PropTypes.any,
  listStyle: PropTypes.any,
  overflow: PropTypes.any,
  overflowX: PropTypes.any,
  overflowY: PropTypes.any,

  position: PropTypes.any,
  top: PropTypes.any,
  left: PropTypes.any,
  right: PropTypes.any,
  bottom: PropTypes.any,
  zIndex: PropTypes.any,
  cursor: PropTypes.any,
  transform: PropTypes.any,
  userSelect: PropTypes.any,

  transition: PropTypes.any,
  objectFit: PropTypes.any,
  opacity: PropTypes.any,
  visibility: PropTypes.any,
  background: PropTypes.any,
  backgroundImage: PropTypes.any,
  backgroundSize: PropTypes.any,
  backgroundPosition: PropTypes.any,
  backgroundRepeat: PropTypes.any,
  useRef: PropTypes.any,
  filter: PropTypes.any,
  hover: PropTypes.object,
  wordBreak: PropTypes.any,
  float: PropTypes.any,
};

export default React.memo(Box);
