import PaginationItem from './PaginationItem';
import Wrap from '../Wrap/Wrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';

const Pagination = ({
  totalItems,
  prev,
  next,
  setPage,
  activePage,
  perPage = 10,
}) => {
  //const perPage = 10;

  const handleClick = (pageIndex) => {
    setPage(pageIndex);
  };

  if (totalItems > 0) {
    // Getting how many pages would be
    const pageCounts = Math.ceil(totalItems / perPage);

    // Building the pages with indexes
    const availablePages = [...new Array(pageCounts)].map(
      (_item, index) => index + 1,
    );

    return (
      <Wrap justifyContent="center" marginY="medium">
        {prev && (
          <PaginationItem pageNumber={activePage} onClick={handleClick} isPrev>
            <AiOutlineArrowLeft />
          </PaginationItem>
        )}
        {availablePages.map((page) => {
          const isActive = activePage === page;

          return (
            <PaginationItem
              key={page}
              onClick={handleClick}
              isActive={isActive}
              pageNumber={page}
            >
              {page}
            </PaginationItem>
          );
        })}
        {next && (
          <PaginationItem pageNumber={activePage} onClick={handleClick} isNext>
            <AiOutlineArrowRight />
          </PaginationItem>
        )}
      </Wrap>
    );
  }

  return null;
};

Pagination.propTypes = {
  totalItems: PropTypes.number,
  perPage: PropTypes.number,
  prev: PropTypes.bool,
  next: PropTypes.bool,
  setPage: PropTypes.func.isRequired,
  activePage: PropTypes.number.isRequired,
};

export default Pagination;
