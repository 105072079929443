import { atom } from 'recoil';

export const goalDataState = atom({
  key: 'goalDataState',
  default: null,
});

export const goalsListState = atom({
  key: 'goalsListState',
  default: null,
});
