import React from 'react';
import { Box } from './SimpleUI';
import styled from 'styled-components';

const Container = styled(Box)`
  display: none;
  @media (max-width: 768px) {
    display: block;
    position: absolute;
    height: 80px;
    bottom: 0;
    min-width: 60px;
    right: 20px;
  }
`;

const Link = styled.a`
  max-width: 300px;
  overflow: hidden;
  transition: all 0.4s ease-out;
  min-width: 60px;
  height: 60px;
  border-radius: 60px;
  cursor: pointer;
  box-shadow: 0 1px 6px rgb(0 0 0 / 6%), 0 2px 32px rgb(0 0 0 / 16%);
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 15;
  background: #25d366;
  & span {
    position: relative;
    top: 19px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    display: block;
    float: left;
    box-sizing: border-box;
    padding-left: 60px;
    padding-right: 20px;
    transition: all 0.3s ease;
  }
`;

const Logo = styled.img`
  position: absolute;
  transition: all 0.3s ease;
  opacity: 1;
  width: 100%;
  height: 100%;
`;

const WhatsappButton = () => {
  return (
    <>
      <Container className="icon">
        <Link
          className="right"
          href="https://api.whatsapp.com/send?phone=5491138108628&text=Hola%20me%20gustar%C3%ADa%20invertir%20con%20ustedes"
          target="_blank"
          without
          rel="noreferrer"
        >
          <Logo src="https://www.pinclipart.com/picdir/big/580-5809783_whatsapp-messaging-apps-android-whatsapp-icon-vector-png.png" />
        </Link>
      </Container>
    </>
  );
};

export default WhatsappButton;
