import React from 'react';
import {
  Box,
  VStack,
  Container,
  SmallCopy,
  Heading,
  PrimaryButton,
  Wrap,
  WrapItem,
  WhiteOutline,
} from './SimpleUI';
import styled from 'styled-components';
import { Cell, Grid } from '@mollycule/lattice';
import { useHistory, Link } from 'react-router-dom';
import WhiteLogo from '../assets/images/white-logo.png';
import Footer2 from '../assets/images/camara-fintech.png';
import Linkedin from '../assets/images/top-ten-linkedin.png';
import { ReactComponent as Twitter } from '../assets/images/twitter_new.svg';
import FooterNav from './FooterNav';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
  FaTiktok,
} from 'react-icons/fa';
import useAuthRecoil from '../hooks/useAuthRecoil';
import useDesignUtils from '../hooks/useDesignUtils';
import VHMobileStack from './VHMobileStack';
import useMixpanelEvents from '../hooks/useMixpanelEvents';

const Footer = () => {
  const history = useHistory();
  const { isMobile } = useDesignUtils();
  const mixpanel = useMixpanelEvents();
  const isMetasUrl = history.location.pathname === '/metas';

  const {
    auth: { isAuth },
  } = useAuthRecoil();

  const footer1 = [
    {
      to: isAuth ? '/mi-perfil' : '/cuenta',
      label: 'Mi cuenta',
    },
    {
      to: isAuth ? '/mi-perfil/inversiones' : '/cuenta',
      label: 'Mis Inversiones',
    },
    {
      to: '/productos',
      label: !isMetasUrl && 'Productos',
    },
    {
      to: '/simple-academy',
      label: 'Simple Academy',
    },
    {
      to: '/proyectos-inmobiliarios',
      label: !isMetasUrl && 'Proyectos',
    },
    {
      to: '/politica-privacidad',
      label: 'Aviso de privacidad',
    },
    {
      to: '/terminos-y-condiciones',
      label: 'Términos y condiciones',
    },
  ];

  const footer2 = [
    {
      to: 'nosotros',
      label: 'Nosotros',
    },
    {
      to: '/#nuestros-inversionistas',
      label: 'Testimonios',
    },
    {
      to: '/#prensa',
      label: 'Medios y Prensa',
    },
    {
      to: '/preguntas-frecuentes',
      label: 'Preguntas Frecuentes',
    },
  ];

  const footer3 = [
    {
      to: 'nosotros#equipo',
      label: 'Hay Equipo',
    },
    {
      to: 'nosotros#rase',
      label: 'Equipo RASE',
    },
    {
      href: 'mailto:rrhh@simplestate.com.ar',
      label: 'Trabajar con nosotros',
    },
  ];
  return null;
  return (
    <Box tag="footer" backgroundColor="purple" paddingY="xxlarge">
      <Container>
        <VStack space="large">
          {isMobile && (
            <Link to="/">
              <Box
                tag="img"
                src={WhiteLogo}
                alt="white logo"
                width="50%"
                display="block"
                marginX="auto"
              />
            </Link>
          )}

          <VStack space="medium">
            <FooterSeparator />
            <FooterInfo>
              <VHMobileStack alignItems="center" space="large">
                <VStack space="medium">
                  <SmallCopy color="white">
                    Simplestate. {new Date().getFullYear()}. Todos los derechos
                    reservados.
                  </SmallCopy>
                  <SmallCopy color="white">
                    SIMPLESTATE no proporciona asesoramiento financiero y nada
                    en esta web debe interpretarse como tal. La información que
                    aparece en esta página es para fines de información general
                    y no constituye un asesoramiento específico. Tampoco es una
                    recomendación para invertir. En caso de tener alguna duda,
                    deberás buscar asesoramiento financiero independiente.
                    Simplestate no es un fideicomiso financiero en los términos
                    previstos en la Ley de Mercado de Capitales N° 26.831, ni
                    tampoco un “fondo de inversión directa” en los términos de
                    las Normas de la Comisión Nacional de Valores (la “CNV”).
                    Por las características propias del fideicomiso y
                    considerando que no se ofrecen valores negociables, no ha
                    sido solicitada autorización a la CNV. Cuando invertís en
                    Simplestate, estás invirtiendo en la compra de un inmueble
                    que posteriormente se venderá, operación que generá un
                    retorno, el cuál se ve reflejado en el resultado de cada
                    inversión individual. Los cálculos de rentabilidad estimada,
                    así como los plazos de inversión publicados en el Sitio, son
                    únicamente estimaciones realizadas por el Sitio en base a
                    estudios de mercado internos. Las inversiones están sujetas
                    a las fluctuaciones de precios y riesgos inherentes al
                    mercado inmobiliario. Los potenciales inversores deberán
                    realizar su propio análisis de riesgo previo a la inversión
                    en distintos proyectos. Cualquier decisión adoptada por los
                    inversores constituirá una decisión informada e
                    independiente de los mismos. Los inversores deberán realizar
                    las comprobaciones, estudios y análisis que consideren
                    pertinentes para adoptar la decisión, de la que Simplestate
                    no responderá en ningún caso.
                  </SmallCopy>
                  <SmallCopy color="white">
                    Fund investment sociedad fiduciaria SA es asesorada por
                    DIGITALICS SRL
                  </SmallCopy>
                </VStack>

                <Box
                  tag="a"
                  href="http://qr.afip.gob.ar/?qr=zuRGzXoAfgQy6Smo17F7Qg,,"
                  target="_F960AFIPInfo"
                  maxWidth="75px"
                  rel="nofollow noopener noreferrer"
                  display="block"
                >
                  <Box
                    tag="img"
                    src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg"
                    width="100%"
                    height="auto"
                    maxWidth="100%"
                  />
                </Box>
              </VHMobileStack>
            </FooterInfo>
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};

const socialnetworks = [
  {
    icon: <FaLinkedinIn size={24} color="white" />,
    url: 'https://www.linkedin.com/company/se-simplestate/',
  },
  {
    icon: <FaFacebookF size={24} color="white" />,
    url: 'https://www.facebook.com/Simplestate-429438400978855',
  },
  {
    icon: <FaInstagram size={24} color="white" />,
    url: 'https://www.instagram.com/se_simplestate/',
  },
  {
    icon: <FaTiktok size={24} color="white" />,
    url: 'https://www.tiktok.com/@se_simplestate',
  },
  {
    icon: <FaYoutube size={24} color="white" />,
    url: 'https://www.youtube.com/channel/UCnQwt2GhwFHQlTA3BdHnbyA',
  },
  {
    icon: <Twitter width="22px" />,
    url: 'https://twitter.com/se_simplestate',
  },
];

const FooterSeparator = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0.7;
  height: 2px;
  width: 100%;
`;

const FooterInfo = styled.div`
  p {
    opacity: 0.5;
  }
`;

export default Footer;
