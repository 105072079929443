import { addSeconds } from 'date-fns';

const useTokenUtils = () => {
  const setToken = (token, expiresIn) => {
    const tokenExpiryDate = addSeconds(new Date(), expiresIn);

    localStorage.setItem('token', token);
    localStorage.setItem('expiresIn', tokenExpiryDate);
  };

  const removeToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expiresIn');
    localStorage.removeItem('invest');
  };

  const getToken = () => {
    const token = localStorage.getItem('token');

    return token;
  };

  const getExpireDate = () => {
    const expiresIn = localStorage.getItem('expiresIn');

    return new Date(expiresIn);
  };

  const setAuthHeader = (data) => {
    const token = data.login.accessToken;
    const expiresIn = data.login.expiresIn;

    setToken(token, expiresIn);
  };

  return { setToken, removeToken, getToken, getExpireDate, setAuthHeader };
};

export default useTokenUtils;
