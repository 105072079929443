import { useEffect } from 'react';
import useDesignUtils from '../hooks/useDesignUtils';

const Chatbot = () => {
  const { isMobile } = useDesignUtils();

  //   useEffect(() => {
  //     const script = document.createElement('script');
  //     script.type = 'text/javascript';
  //     script.async = 1;
  //     script.src = 'https://go.botmaker.com/rest/webchat/p/5XMMM3ZUSH/init.js';

  //     document.body.appendChild(script);
  //     return () => {
  //       document.body.removeChild(script);
  //     };
  //   }, []);

  useEffect(() => {
    setTimeout(() => {
      const getBotIframe = Object.values(
        document.getElementsByTagName('iframe'),
      ).find((item) => item.getAttribute('title') === 'Cliengo Widget');
      try {
        let botContainer = getBotIframe;
        botContainer.classList.add('chat-bot');
      } catch {}
    }, 2000);
  }, [isMobile]);

  return null;
};

export default Chatbot;
