import React from 'react';
import { Link, VStack, Box, Copy } from './SimpleUI';
import PropTypes from 'prop-types';

const FooterNav = ({ title, options }) => {
  return (
    <VStack>
      <Box marginBottom="medium">
        <Copy
          tag="h5"
          color="white"
          fontWeight="medium"
          textTransform="uppercase"
        >
          {title}
        </Copy>
      </Box>
      <Box tag="ul" padding="none" margin="none" listStyle="none">
        {options.map((item, key) => {
          return (
            <Box key={key} tag="li" marginBottom="small">
              {item.href ? (
                <Link
                  href={item.href}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  variant="white"
                  textAlign="left"
                  fontSize="smallCopy"
                >
                  {item.label}
                </Link>
              ) : (
                <Link
                  to={item.to}
                  variant="white"
                  textAlign="left"
                  fontSize="smallCopy"
                >
                  {item.label}
                </Link>
              )}
            </Box>
          );
        })}
      </Box>
    </VStack>
  );
};

FooterNav.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default FooterNav;
