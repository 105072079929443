import { useResetRecoilState } from 'recoil';
import { filtersState } from '../recoil/filters';

const useGetResetFilter = () => {
  const resetFiltersRecoil = useResetRecoilState(filtersState);

  return {
    resetFiltersRecoil,
  };
};

export default useGetResetFilter;
