import React from 'react';
import PropTypes from 'prop-types';
import useDesignUtils from '../../../hooks/useDesignUtils';
import HStack from '../Stack/HStack';
import { FaChevronDown } from 'react-icons/fa';

const DropdownToggle = ({ children }) => {
  const { isMobile } = useDesignUtils();

  return (
    <HStack alignItems="center">
      {children}
      {!isMobile && <FaChevronDown size="11" />}
    </HStack>
  );
};

DropdownToggle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DropdownToggle;
