import React from 'react';
import PropTypes from 'prop-types';
import NavLink from './NavLink';

const NavItem = ({ to, children, exact, variant, onClick, ...rest }) => {
  if (!to && !onClick) {
    throw new Error('You must use either to or onClick props');
  }

  return (
    <NavLink
      to={to}
      activeOnlyWhenExact={exact}
      variant={variant}
      onClick={onClick}
      {...rest}
    >
      {children}
    </NavLink>
  );
};

NavItem.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node.isRequired,
  exact: PropTypes.bool,
  variant: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default NavItem;
