import React from 'react';
import PropTypes from 'prop-types';
import CardBase from './CardBase';

const Tertiary = ({
  tag,
  children,
  loading,
  loadingHeight,
  flat,
  noContentSpace,
  header,
  headerRight,
  boxShadow,
}) => {
  return (
    <CardBase
      tag={tag}
      borderColor="transparent"
      backgroundColor="xlightGray"
      loading={loading}
      loadingHeight={loadingHeight}
      flat={flat}
      noContentSpace={noContentSpace}
      header={header}
      headerRight={headerRight}
      boxShadow={boxShadow}
    >
      {children}
    </CardBase>
  );
};

Tertiary.propTypes = {
  tag: PropTypes.string,
  flat: PropTypes.string,
  children: PropTypes.node.isRequired,
  noContentSpace: PropTypes.bool,
  loading: PropTypes.bool,
  header: PropTypes.node,
  headerRight: PropTypes.node,
  loadingHeight: PropTypes.number,
  boxShadow: PropTypes.string,
};

export default Tertiary;
