import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';

const Wrapper = ({
  children,
  justifyContent,
  marginBottom,
  marginTop,
  marginY,
  flexWrap = 'wrap',
}) => {
  return (
    <Box
      tag="ul"
      display="flex"
      flexWrap={flexWrap}
      listStyle="none"
      alignItems="center"
      margin="none"
      padding="none"
      justifyContent={justifyContent}
      marginBottom={marginBottom}
      marginTop={marginTop}
      marginY={marginY}
    >
      {children}
    </Box>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  marginBottom: PropTypes.string,
  marginTop: PropTypes.string,
  justifyContent: PropTypes.string,
  marginY: PropTypes.string,
  flexWrap: PropTypes.string,
};

export default Wrapper;
