import React from 'react';
import PropTypes from 'prop-types';
import BaseTypography from './Base';

const Label = ({
  tag = 'label',
  textAlign,
  textTransform,
  color,
  children,
  fontWeight = 'medium',
  size = 'label',
  htmlFor,
}) => {
  return (
    <BaseTypography
      tag={tag}
      textAlign={textAlign}
      textTransform={textTransform}
      color={color}
      fontSize={size}
      fontWeight={fontWeight}
      htmlFor={htmlFor}
    >
      {children}
    </BaseTypography>
  );
};

Label.propTypes = {
  tag: PropTypes.string,
  textAlign: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textTransform: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
  fontWeight: PropTypes.string,
  htmlFor: PropTypes.string,
};

export default Label;
