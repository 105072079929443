import React from 'react';
import PropTypes from 'prop-types';
import BaseTypography from './Base';

const Small = ({
  tag = 'small',
  textAlign,
  textTransform,
  color,
  children,
  fontWeight = 'regular',
  className = null,
}) => {
  return (
    <BaseTypography
      tag={tag}
      textAlign={textAlign}
      textTransform={textTransform}
      color={color}
      fontSize="small"
      fontWeight={fontWeight}
      className={className}
    >
      {children}
    </BaseTypography>
  );
};

Small.propTypes = {
  tag: PropTypes.string,
  textAlign: PropTypes.string,
  textTransform: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  fontWeight: PropTypes.string,
  className: PropTypes.string,
};

export default Small;
