import Box from '../Box';
import React from 'react';
import { useClickAway } from 'react-use';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DropdownWrapper = ({ children, isOpen = false, toggle }) => {
  const ref = React.useRef(null);

  const [HEADER, BODY] = React.Children.toArray(children);

  useClickAway(ref, () => {
    if (isOpen) {
      toggle();
    }
  });

  return (
    <Box position="relative" useRef={ref}>
      <Box tag="button" onClick={toggle}>
        {HEADER}
      </Box>
      <BodyWrapper isOpen={isOpen}>{BODY}</BodyWrapper>
    </Box>
  );
};

const BodyWrapper = styled.section`
  > ul {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  }
`;

DropdownWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
};

export default DropdownWrapper;
