import { useRecoilState } from 'recoil';
import { shareModalState } from '../recoil/shareModal';

const useToggleShare = () => {
  const [openShareModal, setShareModal] = useRecoilState(shareModalState);

  const toggleShareModal = () => {
    setShareModal((prev) => ({
      ...prev,
      isOpen: !prev.isOpen,
    }));
  };

  const setShareData = (data) => {
    setShareModal((prev) => ({
      ...prev,
      metaData: {
        ...data,
      },
    }));
  };

  return {
    openShareModal,
    toggleShareModal,
    setShareData,
  };
};

export default useToggleShare;
