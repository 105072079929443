import React from 'react';
import PropTypes from 'prop-types';
import Wrap from '../Wrap/Wrapper';
import Box from '../Box';

const Nav = ({ children, centerItems, spaceBetween }) => {
  const resolveJustifyContent = () => {
    if (spaceBetween) {
      return 'space-between';
    }

    if (centerItems) {
      return 'center';
    }

    return undefined;
  };

  return (
    <Box
      borderWidth="3px"
      borderStyle="solid"
      borderColor="transparent"
      borderColorBottom="lightGray"
      position="relative"
      justifyContent={spaceBetween && 'space-between'}
    >
      <Wrap justifyContent={resolveJustifyContent()}>{children}</Wrap>
    </Box>
  );
};

Nav.propTypes = {
  children: PropTypes.node.isRequired,
  centerItems: PropTypes.bool,
  spaceBetween: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

export default Nav;
