import React from 'react';
import PropTypes from 'prop-types';
import CardBase from './CardBase';
import Heading from '../Typography/Heading';
import Box from '../Box';
import SmallCopy from '../Typography/SmallCopy';
import Label from '../Typography/Label';
import { mapInvestmentTypeColors } from '../../../helpers/index';

const Featured = ({ id, title, description, features }) => {
  return (
    <CardBase
      tag="article"
      borderColor="transparent"
      backgroundColor={mapInvestmentTypeColors(id)}
      height="100%"
      noContentSpace
    >
      <Box padding="medium">
        <Heading textAlign="center" color="white" fontWeight="medium">
          {title}
        </Heading>
      </Box>
      <Box paddingY="medium" paddingX="large" backgroundColor="xlightBlack">
        <SmallCopy textAlign="center" color="white">
          {description}
        </SmallCopy>
      </Box>
      <Box padding="medium">
        <Box tag="ul" margin="none" $color="white" paddingLeft="large">
          {features.map((item, key) => (
            <Box key={key} tag="li">
              <Label tag="p" color="white" fontWeight="regular">
                {item}
              </Label>
            </Box>
          ))}
        </Box>
      </Box>
    </CardBase>
  );
};

Featured.propTypes = {
  features: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default Featured;
