import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';

const Collapsabler = ({ children, isOpen, noSpaces }) => {
  const animationVariants = {
    open: { opacity: 1, height: 'auto' },
    closed: { opacity: 0, height: 0 },
  };

  return (
    <motion.div
      style={{ overflow: isOpen && noSpaces ? 'visible' : 'hidden', height: 0 }}
      animate={isOpen ? 'open' : 'closed'}
      transition={{
        duration: 0.2,
      }}
      variants={animationVariants}
    >
      {children}
    </motion.div>
  );
};

Collapsabler.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  noSpaces: PropTypes.bool,
};

export default Collapsabler;
