import { useMutation } from '@apollo/client';
import { handleGraphqlError } from '../helpers';
import { UPDATE_NOTIFICATION } from '../apollo/mutations';

const useUpdateNotification = () => {
  const [
    handleUpdateNotification,
    { loading, data, error: createError },
  ] = useMutation(UPDATE_NOTIFICATION);

  const error = createError ? handleGraphqlError(createError) : undefined;

  return { handleUpdateNotification, loading, data, error };
};

export default useUpdateNotification;
