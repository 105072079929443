import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import styled, { css } from 'styled-components';
import Spacer from '../Spacer';
import Box from '../Box';
import Copy from '../Typography/Copy';

const CardBase = ({
  children,
  padding = 'medium',
  loading,
  loadingHeight,
  flat,
  noContentSpace,
  header,
  headerRight,
  headerColor,
  boxShadow,
  borderRadius = 'small',
  ...restProps
}) => {
  if (loading) {
    return <Skeleton height={loadingHeight} width="100%" />;
  }

  const resolveFlatCorders = () => {
    if (flat === 'top' || flat === 'bottom') {
      return flat;
    }

    return undefined;
  };

  return (
    <View
      borderWidth="0.1px"
      borderStyle="solid"
      borderRadius={borderRadius}
      flat={resolveFlatCorders()}
      position="relative"
      boxShadow={boxShadow}
      {...restProps}
    >
      {header ? (
        <Box
          tag="header"
          borderStyle="solid"
          borderWidth="1px"
          borderColorX="transparent"
          borderColorTop="transparent"
          borderColorBottom="lightGray"
          paddingX="medium"
          minHeight="60px"
          display="flex"
          alignItems="center"
        >
          {typeof header === 'string' && (
            <Copy variant="h2" color={headerColor} fontWeight="medium">
              {header}
            </Copy>
          )}
          {headerRight && (
            <>
              <Spacer />
              {headerRight}
            </>
          )}
        </Box>
      ) : null}
      <Box padding={noContentSpace ? 'none' : padding}>{children}</Box>
    </View>
  );
};

const View = styled(Box)`
  ${({ flat }) =>
    flat &&
    css`
    border-${flat}-left-radius: 0px;
    border-${flat}-right-radius: 0px;
`}
`;

CardBase.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.string,
  loading: PropTypes.bool,
  loadingHeight: PropTypes.number,
  flat: PropTypes.oneOf(['top', 'bottom']),
  noContentSpace: PropTypes.bool,
  header: PropTypes.string,
  headerRight: PropTypes.node,
  headerColor: PropTypes.string,
  boxShadow: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  borderRadius: PropTypes.string,
};

export default CardBase;
