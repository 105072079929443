import { useQuery } from '@apollo/client';
import { GET_NOTIFICATIONS } from '../apollo/queries';

const useGetNotifications = () => {
  const { loading, data, error, called, refetch } = useQuery(
    GET_NOTIFICATIONS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    },
  );

  return { loading, data, error, called, refetch };
};
export default useGetNotifications;
