import React from 'react';
import PropTypes from 'prop-types';
import WrapItem from '../Wrap/WrapItem';

const NavItem = ({ children, fullWidth }) => {
  return (
    <WrapItem marginY="none" marginX="none" flex={fullWidth && '1'}>
      {children}
    </WrapItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
};

export default NavItem;
