import React from 'react';
import PropTypes from 'prop-types';
import Badge from './Badge';
import Wrap from './Wrap/Wrapper';
import WrapItem from './Wrap/WrapItem';
import Label from './Typography/Label';
import Skeleton from 'react-loading-skeleton';

const TagFilters = ({
  filters,
  activeFilters,
  handleRemoveFilters,
  loading,
}) => {
  if (loading) {
    return <Skeleton width="100%" height={50} />;
  }

  const getSelectedFilterLabel = (filterName, id) => {
    const selectedCategory = filters?.find(
      (category) => category.filter === filterName,
    );

    const selectedFilter = selectedCategory?.fields?.find(
      (filter) => filter.id === id,
    );

    return selectedFilter?.name;
  };

  return (
    <Wrap space="small" marginBottom="medium">
      {activeFilters?.map((filter) => {
        const label = getSelectedFilterLabel(filter.filter, filter.id);
        return (
          <WrapItem key={filter.id}>
            <Badge id={filter.id} onClick={handleRemoveFilters}>
              <Label color="white" fontWeight="medium">
                {label}
              </Label>
            </Badge>
          </WrapItem>
        );
      })}
    </Wrap>
  );
};

TagFilters.propTypes = {
  filters: PropTypes.array,
  activeFilters: PropTypes.array,
  handleRemoveFilters: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default TagFilters;
