import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';

const DropdownMenu = ({ children }) => {
  return (
    <Box
      tag="ul"
      position="absolute"
      minWidth="10rem"
      backgroundColor="white"
      top="105%"
      right="0"
      boxShadow="medium"
      zIndex="2"
      padding="none"
      margin="none"
    >
      {children}
    </Box>
  );
};

DropdownMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DropdownMenu;
