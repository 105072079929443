import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { theme } from '../../theme';
import { Copy } from '../SimpleUI';

const SubnavBar = styled.div`
  background: white;
  padding-left: 10px;
  position: absolute;
  top: 41px;
  min-width: 230px;
  box-shadow: 0px 10px 10px rgba(75, 65, 132, 0.2);
  z-index: 1;
  animation-name: softness;
  animation-duration: 0.3s;
  @keyframes softness {
    from {
      opacity: 0;
      visibility: hidden;
    }
    to {
      opacity: 1;
      visibility: visible;
    }
  }
  @media (max-width: ${theme.medias.mobile}px) {
  }
`;

const LinkStyle = css`
  display: block;
  text-decoration: none;
  padding: 8px 5px;
  text-align: left;
`;

const LinkUnit = styled.a`
  ${LinkStyle}
`;

const LinkRouter = styled(Link)`
  ${LinkStyle}
  &>p:hover {
    font-weight: 600;
  }
`;

const SubNavBar = ({ subLinks, toggle }) => {
  return (
    <SubnavBar>
      {subLinks?.map((link) => (
        <div key={link.name}>
          {link.url && (
            <LinkUnit
              href={link.url}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <Copy>{link.name}</Copy>
            </LinkUnit>
          )}
          {link.to && (
            <LinkRouter
              to={link.to}
              onClick={() => (toggle(), link.setStep && link.setStep())}
            >
              <Copy>{link.name}</Copy>
            </LinkRouter>
          )}
        </div>
      ))}
    </SubnavBar>
  );
};

SubNavBar.propTypes = {
  subLinks: PropTypes.array,
  toggle: PropTypes.func,
  setStep: PropTypes.func,
};

export default SubNavBar;
