import { theme } from '../theme';
import { useMedia } from 'react-use';

const useDesignUtils = () => {
  const isMobile = useMedia(`(max-width: ${theme.medias.mobile}px)`);
  const isTablet = useMedia(`(max-width: ${theme.medias.tablet}px)`);

  const resolveProps = (valueOrMap) => {
    if (!valueOrMap) {
      return undefined;
    }

    if (typeof valueOrMap !== 'object') {
      return valueOrMap;
    }

    if (valueOrMap.mobile && valueOrMap.desktop) {
      const value = isMobile ? valueOrMap.mobile : valueOrMap.desktop;
      return value ? value : undefined;
    }

    console.error(
      `Box with responsive object must use "mobile" and "desktop" keys.`,
    );

    return undefined;
  };

  const resolveThemeProps = (themeKey, valueOrMap) => {
    if (!valueOrMap) {
      return undefined;
    }

    if (typeof valueOrMap !== 'object') {
      const scaleKey = valueOrMap;
      const scales = theme[themeKey];
      return scales ? scales[scaleKey] : undefined;
    }

    if (valueOrMap.mobile && valueOrMap.desktop) {
      const scales = theme[themeKey];
      const value = isMobile
        ? scales[valueOrMap.mobile]
        : scales[valueOrMap.desktop];
      return value ? value : undefined;
    }

    console.error(
      `Box with responsive object must use "mobile" and "desktop" keys.`,
    );
  };

  return { resolveProps, resolveThemeProps, isMobile, theme, isTablet };
};

export default useDesignUtils;
