import React from 'react';
import {
  Box,
  VStack,
  Container,
  SmallCopy,
  Heading,
  PrimaryButton,
  Wrap,
  WrapItem,
  WhiteOutline,
} from './SimpleUI';
import styled from 'styled-components';
import { Cell, Grid } from '@mollycule/lattice';
import { useHistory, Link } from 'react-router-dom';
import WhiteLogo from '../assets/images/white-logo.png';
import Footer2 from '../assets/images/camara-fintech.png';
import Linkedin from '../assets/images/top-ten-linkedin.png';
import { ReactComponent as Twitter } from '../assets/images/twitter_new.svg';
import FooterNav from './FooterNav';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
  FaTiktok,
} from 'react-icons/fa';
import useAuthRecoil from '../hooks/useAuthRecoil';
import useDesignUtils from '../hooks/useDesignUtils';
import VHMobileStack from './VHMobileStack';
import useMixpanelEvents from '../hooks/useMixpanelEvents';
import LogoImage from '../assets/images/logo.png';

const FooterAnelo = () => {
  const history = useHistory();
  const { isMobile } = useDesignUtils();
  const mixpanel = useMixpanelEvents();
  const isMetasUrl = history.location.pathname === '/metas';

  const {
    auth: { isAuth },
  } = useAuthRecoil();

  return (
    <Box tag="footer" backgroundColor="white" paddingY="xxlarge">
      <Container>
        <VStack space="large">
          {/* {isMobile && (
            <Link to="/">
              <Box
                tag="img"
                src={WhiteLogo}
                alt="white logo"
                width="50%"
                display="block"
                marginX="auto"
              />
            </Link>
          )} */}

          <VStack space="medium">
            <FooterSeparator />
            <FooterInfo>
              <VHMobileStack alignItems="center" space="large">
                <VStack space="medium">
                  <SmallCopy textAlign={'center'}>
                    <Box
                      tag="img"
                      src={LogoImage}
                      width="100%"
                      height="auto"
                      maxWidth="170px"
                    />
                  </SmallCopy>
                </VStack>

                <VStack space="medium">
                  <SmallCopy
                    color="black"
                    fontWeight="bold"
                    textAlign={'center'}
                  >
                    ¿DÓNDE NOS ENCONTRAMOS?
                  </SmallCopy>
                  <SmallCopy color="black" textAlign={'center'}>
                    Buenos Aires, Argentina
                  </SmallCopy>
                  <SmallCopy color="black" textAlign={'center'}>
                    Lorem ipsum 1234
                  </SmallCopy>
                </VStack>
              </VHMobileStack>
            </FooterInfo>
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};

const FooterSeparator = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  // opacity: 0.7;
  height: 2px;
  width: 100%;
`;

const FooterInfo = styled.div`
  p {
    //opacity: 0.5;
  }
`;

export default FooterAnelo;
