import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useClickAway } from 'react-use';
import styled from 'styled-components';
import Caret from '../../assets/icons/caret.svg';
import useDesignUtils from '../../hooks/useDesignUtils';
import { theme } from '../../theme';
import { Box, Copy, Label } from '../SimpleUI';
import SubNavBar from './SubNavBar';

const NavLink = ({
  to,
  activeOnlyWhenExact,
  children,
  onClick,
  variant,
  ...rest
}) => {
  const { isMobile } = useDesignUtils();
  const [subnavOpen, setSubnavOpen] = useState(false);
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  const getFontSize = () => {
    if (isMobile) {
      if (variant === 'small') {
        return Label;
      }

      return Copy;
    } else {
      return Copy;
    }
  };

  const Text = getFontSize();
  const outsideClick = useRef(null);

  const LinkText = !to ? StyledButton : StyledLink;

  const toggleSubnav = () => {
    setSubnavOpen(!subnavOpen);
  };

  useClickAway(outsideClick, () => {
    if (subnavOpen && !isMobile) {
      toggleSubnav();
    }
  });

  return (
    <LinkText
      to={to}
      active={match}
      $hasSubnav={subnavOpen}
      onClick={() =>
        rest.subLinks?.length ? toggleSubnav() : onClick && onClick()
      }
      ref={outsideClick}
    >
      <Text
        tag="p"
        color={match ? 'green' : 'purple'}
        fontWeight={isMobile ? 'medium' : 'regular'}
      >
        {children}
        {rest.subLinks && (
          <Box
            tag="img"
            src={Caret}
            alt="caret"
            cursor="pointer"
            marginLeft="small"
            transform={`rotate(${subnavOpen ? '180deg' : '0deg'})`}
            transition="all 0.2s ease"
          />
        )}
      </Text>
      {!to && subnavOpen && (
        <SubNavBar subLinks={rest.subLinks} toggle={onClick} />
      )}
    </LinkText>
  );
};

const StyledButton = styled.button`
  display: block;
  position: relative;
  ${({ $hasSubnav }) =>
    $hasSubnav &&
    `
    @media (max-width: ${theme.medias.mobile}px) {

    }
  `}
`;

const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;

  p {
    position: relative;

    @media (min-width: ${theme.medias.mobile}px) {
      &::after {
        content: '';
        position: absolute;
        display: block;
        height: 2px;
        width: 100%;
        background: ${({ theme, active }) =>
          active ? process.env.REACT_APP_CUSTOM_CONFIG_COLOR_PRIMARY : 'none'};
        bottom: -5px;
      }
    }
  }
`;

NavLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  activeOnlyWhenExact: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  variant: PropTypes.string,
};

export default NavLink;
