import React, { useEffect } from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { toast } from 'react-toastify';
import { useCopyToClipboard } from 'react-use';
import { BASE_URL } from '../api';
import useDesignUtils from '../hooks/useDesignUtils';
import useToggleShare from '../hooks/useToggleShare';
import {
  Box,
  Heading,
  HStack,
  SecondaryButton,
  TextInput,
  VStack,
} from './SimpleUI';
import VHMobileStack from './VHMobileStack';

const ShareProject = () => {
  const [copyState, copyToClipboard] = useCopyToClipboard();
  const { openShareModal } = useToggleShare();
  const { isMobile } = useDesignUtils();

  const shareUrl = `${BASE_URL}/proyectos-inmobiliarios/proyecto/${openShareModal.metaData.id}`;
  const title = `${process.env.REACT_APP_CUSTOM_CONFIG_BRAND_NAME} - Vení a invertir en ${openShareModal.metaData.name}`;

  const handleCopy = () => {
    copyToClipboard(shareUrl);
  };

  useEffect(() => {
    if (copyState.value) {
      toast.info('El enlace se ha copiado en el portapapeles');
    }
  }, [copyState.value]);

  return (
    <Box paddingTop={{ mobile: 'medium', desktop: 'small' }}>
      <VStack space="medium">
        <VHMobileStack space="medium" alignItems="center">
          <TextInput type="text" name="url" value={shareUrl} size="full" />

          <Box width={{ mobile: '100%', desktop: '240px' }}>
            <SecondaryButton
              type="button"
              onClick={handleCopy}
              size="full"
            >
              Copiar Link
            </SecondaryButton>
          </Box>
        </VHMobileStack>
        <VHMobileStack justifyContent="center" space="large">
          <HStack space="small" alignItems="center">
            <TwitterShareButton url={shareUrl} title={title}>
              <Box
                display="flex"
                alignItems="center"
                style={{ gap: '0.75rem' }}
              >
                <TwitterIcon size={42} round />
                {isMobile && <Heading>Twitter</Heading>}
              </Box>
            </TwitterShareButton>
          </HStack>
          <HStack space="small" alignItems="center">
            <FacebookShareButton url={shareUrl} title={title}>
              <Box
                display="flex"
                alignItems="center"
                style={{ gap: '0.75rem' }}
              >
                <FacebookIcon size={42} round />
                {isMobile && <Heading>Facebook</Heading>}
              </Box>
            </FacebookShareButton>
          </HStack>
          <HStack space="small" alignItems="center">
            <WhatsappShareButton url={shareUrl} title={title}>
              <Box
                display="flex"
                alignItems="center"
                style={{ gap: '0.75rem' }}
              >
                <WhatsappIcon size={42} round />
                {isMobile && <Heading>Whatsapp</Heading>}
              </Box>
            </WhatsappShareButton>
          </HStack>
          <HStack space="small" alignItems="center">
            <EmailShareButton url={shareUrl} title={title}>
              <Box
                display="flex"
                alignItems="center"
                style={{ gap: '0.75rem' }}
              >
                <EmailIcon size={42} round />
                {isMobile && <Heading>Email</Heading>}
              </Box>
            </EmailShareButton>
          </HStack>
        </VHMobileStack>
      </VStack>
    </Box>
  );
};

export default ShareProject;
