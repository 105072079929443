import React from 'react';
import ButtonBase from './ButtonBase';
import PropTypes from 'prop-types';

const NewSecondary = ({
  type = 'button',
  onClick,
  children,
  disabled,
  size,
  loading,
  iconStart,
  iconEnd,
  tag,
  href,
  download,
  target,
  alt,
  fontSize = 'Copy',
  buttonDesign = 'secondary',
  hover,
  variant,
  ...restProps
}) => {
  const availableColors = {
    green: { defaultColor: 'primary', hoverColor: 'secondary' },
    purple: { defaultColor: 'primary', hoverColor: 'secondary' },
  };
  const colorVariant = availableColors[variant] || availableColors['green'];
  return (
    <ButtonBase
      type={type}
      onClick={onClick}
      disabled={disabled}
      size={size}
      loading={loading}
      iconStart={iconStart}
      iconEnd={iconEnd}
      backgroundColor="white"
      borderColor={colorVariant.defaultColor}
      color={colorVariant.defaultColor}
      tag={tag}
      href={href}
      download={download}
      target={target}
      alt={alt}
      hover={{
        backgroundColor: hover
          ? hover.backgroundColor
          : colorVariant.hoverColor,
        borderColor: hover
          ? hover.backgroundColor
          : colorVariant.hoverColor
      }}
      fontSize={fontSize}
      borderWidth="2px"
      buttonDesign={buttonDesign}
      {...restProps}
    >
      {children}
    </ButtonBase>
  );
};

NewSecondary.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  size: PropTypes.string,
  loading: PropTypes.bool,
  iconStart: PropTypes.node,
  iconEnd: PropTypes.node,
  tag: PropTypes.string,
  href: PropTypes.string,
  download: PropTypes.bool,
  target: PropTypes.string,
  alt: PropTypes.string,
  fontSize: PropTypes.string,
  hover: PropTypes.object,
  buttonDesign: PropTypes.string,
  variant: PropTypes.string,
};

export default NewSecondary;
