import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '../Box';
import Copy from '../Typography/Copy';

const NavButton = ({ children, active, onClick, dataTabs }) => {
  return (
    <Wrapper
      type="button"
      tag="button"
      paddingX={{ mobile: 'small', desktop: 'medium' }}
      paddingY="medium"
      color={active ? 'purple' : 'black'}
      position="relative"
      active={active}
      onClick={onClick}
      width="100%"
      backgroundColor={active ? 'white' : 'lightGray'}
      borderRadius="topBorder"
      data-home-tabs={dataTabs}
    >
      <Copy
        fontWeight="medium"
        fontSize="bigCopy"
        color={active ? 'purple' : 'black'}
      >
        {children}
      </Copy>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  position: relative;
  box-shadow: ${({ active }) => (active ? '1px -1px 6px #888' : 'none')};
  z-index: ${({ active }) => (active ? '1' : '0')};
  &::after {
    position: absolute;
    content: ' ';
    height: 6px;
    bottom: -5px;
    left: 0px;
    right: 0px;
    background-color: ${({ theme, active }) =>
      active ? theme.colors.white : 'transparent'};
  }
`;

NavButton.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  dataTabs: PropTypes.string,
};

export default NavButton;
